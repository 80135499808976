import * as React from 'react';
import Layout from '../components/dfa-theme/layout';
import { Link } from 'gatsby';
import ContentBlock from '../components/content-block';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { CONSTANTS } from '../utils/constants';

import '../scss/pages/404.scss';

// markup
const NotFoundPage = () => {
	const metaTags = {
		name: 'Privacy',
		description: 'Deerfield 404',
		keywords: 'Deerfield, 404',
	};

	const goBack = () => {
		if (typeof window === 'undefined') {
			window.history.go(-1);
		}
	};

	return (
		<Layout meta={metaTags}>
			<div className='error-hero-container'>
				<ContentBlock
					outerBgColor={CONSTANTS.WHITE}
					className='errors block smallHero'
				>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8} className='careers-content title-block'>
								<p className='header-orange dark'>404 error</p>
								<h1 className='dark'>
									Oh deer. Look's like you've lost your way.
								</h1>
								<p>Sorry, the page you were looking for could not be found.</p>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8} className='careers-btns'>
								<Link className='dfa-button-orange' name='firstLink' to='/'>
									Home
								</Link>
								<a
									href='#'
									className='dfa-button-orange'
									name='firstLink'
									onClick={goBack}
								>
									Back
								</a>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>
			</div>
		</Layout>
	);
};

export default NotFoundPage;
